var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "monitoring"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "cols": "12"
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "d-flex align-items-center"
  })])], 1), _c('b-col', {
    staticClass: "text-right mb-2",
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "custom-search d-flex justify-content-end"
  }, [_vm.acl() ? _c('b-button', {
    staticClass: "button-tambah mr-1",
    attrs: {
      "id": "tambah-data",
      "variant": 'primary',
      "to": {
        name: _vm.enableCreateForm,
        params: {
          previous_link: {
            name: 'form-kegiatan-operasional'
          }
        }
      }
    }
  }, [_c('span', [_c('strong', [_c('feather-icon', {
    attrs: {
      "icon": "FilePlusIcon"
    }
  }), _vm._v(" Buat Kegiatan Bongkar / Muat di Dermaga ")], 1)])]) : _vm._e()], 1)])], 1), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e(), _c('vue-good-table', {
    key: _vm.yoRender,
    ref: "goodTable",
    staticClass: "yo-good-table",
    attrs: {
      "mode": "remote",
      "total-rows": _vm.total_records,
      "columns": _vm.columns,
      "is-loading": _vm.yo_isLoading,
      "rows": _vm.rows,
      "rtl": _vm.direction,
      "search-options": {
        enabled: true,
        skipDiacritics: true,
        placeholder: 'Search this table ( for dateTime, type with format YYYY-MM-DD HH:mm:ss )'
      },
      "select-options": {
        enabled: false,
        selectOnCheckboxOnly: true,
        // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'Rows Selected',
        clearSelectionText: 'Clear',
        disableSelectInfo: false,
        // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.serverParams.perPage,
        mode: 'pages',
        position: 'bottom',
        jumpFirstOrLast: true,
        infoFn: function infoFn(params) {
          return "my own page ".concat(params.firstRecordOnPage);
        }
      },
      "group-options": {
        enabled: _vm.thereIsGroup
      },
      "compact-mode": "",
      "theme": "polar-bear",
      "style-class": "vgt-table bordered table-hover",
      "row-style-class": _vm.rowStyleClassFn,
      "line-numbers": true,
      "fixed-header": false,
      "max-height": "700px"
    },
    on: {
      "on-search": _vm.onSearchTable,
      "on-selected-rows-change": _vm.selectionChanged,
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [_c('transition', {
          attrs: {
            "mode": "out-in"
          }
        }, [props.column.field === 'jns_prdk_curah_cair' ? _c('span', [props.row.jns_prdk_curah_cair ? _c('span', [_c('b-avatar', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mb-1",
          attrs: {
            "title": "Jenis Produk",
            "variant": "light-primary",
            "size": "60"
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "25",
            "icon": 'CloudDrizzleIcon'
          }
        })], 1), _c('strong', [_vm._v(" Curah Cair : ")]), _c('span', [_vm._v(_vm._s(props.row.jns_prdk_curah_cair))])], 1) : _vm._e(), props.row.jns_prdk_curah_kering ? _c('span', [_c('b-avatar', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mb-1",
          attrs: {
            "title": "Jenis Produk",
            "variant": "light-warning",
            "size": "60"
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "25",
            "icon": 'SunriseIcon'
          }
        })], 1), _c('strong', [_vm._v(" Curah Kering : ")]), _c('span', [_vm._v(_vm._s(props.row.jns_prdk_curah_kering))])], 1) : _vm._e(), props.row.jns_prdk_unitized ? _c('span', [_c('b-avatar', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mb-1",
          attrs: {
            "title": "Jenis Produk",
            "variant": "light-secondary",
            "size": "60"
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "25",
            "icon": 'UnderlineIcon'
          }
        })], 1), _c('strong', [_vm._v(" Unitized : ")]), _c('span', [_vm._v(_vm._s(props.row.jns_prdk_unitized))])], 1) : _vm._e(), _c('hr'), _c('b-avatar', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mb-1",
          attrs: {
            "title": "Volume & Satuan",
            "variant": "light-primary",
            "size": "60"
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "25",
            "icon": 'Volume2Icon'
          }
        })], 1), _c('span', [_vm._v(" " + _vm._s(_vm.formatNumber(props.row.volume)))]), _c('strong', [_c('span', [_vm._v(" " + _vm._s(props.row.satuan))])]), _c('hr'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'SettingsIcon'
          }
        }), _vm._v(" Metode Pelayanan "), _c('feather-icon', {
          attrs: {
            "icon": 'SettingsIcon'
          }
        })], 1), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": props.row.metode_pelayanan.toLowerCase() == 'semi mekanis' ? 'light-primary' : 'light-primary'
          }
        }, [_vm._v(" " + _vm._s(props.row.metode_pelayanan) + " ")])], 1) : _vm._e(), props.column.field === 'kegiatan' ? _c('span', [_c('b-row', [_c('b-col', [_c('b-avatar', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mb-1",
          attrs: {
            "title": "Persiapan Berkegiatan",
            "variant": "light-primary",
            "size": "35"
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "20",
            "icon": 'FlagIcon'
          }
        })], 1), _c('strong', [_c('span', [_vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.wkt_keg_persiapan)))])]), _c('hr'), _c('b-avatar', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mb-1",
          attrs: {
            "title": "Mulai Berkegiatan",
            "variant": "light-primary",
            "size": "35"
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "20",
            "icon": 'BatteryChargingIcon'
          }
        })], 1), _c('i', [_c('span', [_vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.wkt_keg_mulai)))])]), _c('hr'), _c('b-avatar', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mb-1",
          attrs: {
            "title": "Selesai Berkegiatan",
            "variant": "light-primary",
            "size": "35"
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "20",
            "icon": 'BatteryIcon'
          }
        })], 1), _c('strong', [_c('span', [_vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.wkt_keg_selesai)))])]), _c('hr')], 1), _c('b-col', [_c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'ClockIcon'
          }
        }), _vm._v(" Durasi :")], 1), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": 'primary'
          }
        }, [_c('strong', [_c('span', [_vm._v(" " + _vm._s(props.row.wkt_durasi_keg))])])]), _c('hr'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'BarChartIcon'
          }
        }), _vm._v(" Produktivitas / Jam :")], 1), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": 'primary'
          }
        }, [_c('strong', [_c('span', [_vm._v(" " + _vm._s(props.row.wkt_produktivitas))])])]), _c('hr'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'TargetIcon'
          }
        }), _vm._v(" No. Surat Jalan :")], 1), _c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "pill": "",
            "variant": 'warning'
          }
        }, [_c('strong', [_c('span', [_vm._v(" " + _vm._s(props.row.no_surat_jln))])])])], 1)], 1)], 1) : _vm._e(), props.column.field === 'catatan' ? _c('span', [_c('b-avatar', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mb-1",
          attrs: {
            "title": "TKBM",
            "variant": "light-primary",
            "size": "35"
          }
        }, [_c('feather-icon', {
          attrs: {
            "size": "20",
            "icon": 'CrosshairIcon'
          }
        })], 1), _c('span', [_vm._v(" " + _vm._s(props.row.tkbm))]), _vm._v(" "), _c('strong', [_vm._v(" ( TKBM )")]), _c('hr'), _c('strong', [_c('feather-icon', {
          attrs: {
            "icon": 'ToolIcon'
          }
        }), _vm._v(" Peralatan "), _c('strong', [_vm._v(" ( Operator / Driver )")])], 1), _c('b-form-tags', {
          attrs: {
            "value": _vm.perlatanData(props.row.opr_keg_mb_peralatans),
            "input-id": "tag-kegiatan",
            "input-attrs": {
              'aria-describedby': 'tags-remove-on-delete-help'
            },
            "width": "75",
            "separator": ",",
            "placeholder": "",
            "disabled": true,
            "remove-on-delete": "",
            "no-add-on-enter": ""
          }
        }), _c('small', [_c('i', [_c('strong', [_vm._v(" Signatured By : " + _vm._s(props.row.user.full_name)), _c('br'), _vm._v("Last Updated AT : " + _vm._s(_vm.dateHumanReadAble(props.row.updated_at)) + " ")])])])], 1) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-button', {
          attrs: {
            "variant": 'outline-primary',
            "block": "",
            "to": {
              name: 'form-kegiatan-operasional-view',
              params: {
                id: props.row.id,
                api: 'opr_form_kegiatan',
                previous_link: {
                  name: 'form-kegiatan-operasional'
                }
              }
            },
            "size": "sm"
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": 'PenToolIcon',
            "size": "25"
          }
        })], 1)], 1), _c('hr')]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }