<template>
  <div>
    <h1>
      <feather-icon size="30" :icon="'BoxIcon'" />
      KEGIATAN BONGKAR / MUAT DI DERMAGA ( OPR Dermaga )
      <feather-icon size="30" :icon="'BoxIcon'" />
    </h1>

    <BCardActions
      title=""
      sub-title=""
      action-collapse
      class="mt-1"
      :collapsed="false"
      :no-actions="true"
    >
      <span class="mt-2">
        <!-- <feather-icon :icon="'BoxIcon'" /> -->
        <small>
          <strong>
            <i> [ {{ $t('quotes_opr_dermaga') }} 📚 ] </i>
          </strong>
        </small>
      </span>
      <Table />
    </BCardActions>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Table from './table.vue'

export default {
  components: {
    BCardActions,
    Table
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  }
}
</script>
